import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { WidgetContainer } from "../components/WidgetContainer";
import Maira from '../images/maira-advisor-icon.png';
import MinusIcon from '../components/icons/minusIcon';
import {
    MinChatUiProvider,
    MainContainer,
    MessageContainer,
    MessageHeader,
} from "@minchat/react-chat-ui";
import MessageInput from '../components/message-input/index';
import MessageList from "../components/message/MessageList";
import { isMobile } from 'react-device-detect';
import { useChat } from "../contexts/ChatProvider";
import { nanoid } from "nanoid";
import { generateUserId } from '../utils/user';

function LandingPage() {
    const {
        sendMessage,
        makeApiCall,
        setShowTypingIndicator,
        loading,
        loadConversation,
        setLoadConversation,
        header,
        greeting,
        setGreeting,
        setClientKey,
        setDataPrefix,
        setHeader,
        setUserId,
        setCustomTheme
    } = useChat();
    const [searchParams] = useSearchParams();
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        const clientKey = searchParams.get('client-key') || "E93wTC6HclocljPELpY1M-H5OpfLSC909tSB7nDO4uU=";
        setClientKey(clientKey);
        setDataPrefix("homepage");
        setHeader("Maira AI Advisor");
        setCustomTheme("#3A6D8C");
        if (!greeting) {
            setGreeting("How can I help you?")
        }
        if (!loadConversation) {
            setLoadConversation(true);
        }
        const storedUserId = localStorage.getItem('homepage_userId');
        setUserId(storedUserId || generateUserId());
    }, [])

    const handleSend = (message) => {
        setShowTypingIndicator(true);
        const newMessages = [
            {
                _id: nanoid(),
                text: message,
                user: "me",
                type: "outgoing",
                createdAt: new Date(),
            },
        ];
        sendMessage(newMessages);
        makeApiCall(message).then(() => {
            setShowTypingIndicator(false);
        }).catch(err => {
            console.log(err);
            sendMessage({
                _id: nanoid(),
                text: "Error sending message, please try again",
                user: "me",
                type: "incoming",
                createdAt: new Date(),
            });
            setShowTypingIndicator(false);
        });
    }
    return (
        <div className='relative h-full overflow-y-scroll bg-gradient-to-r from-white to-blue-200'>
            {isOpen && isMobile &&
                <div className='h-full '>
                    <div
                        className="chatbot-container-landing-page-mobile w-full shadow-gray-400  shadow-md bg-white rounded-xl"
                    >
                        <MinChatUiProvider
                            colorSet={{}}
                        >
                            <MainContainer>
                                <MessageContainer>
                                    <MessageHeader showBack={false}>
                                        <div style={{ display: "flex", justifyContent: "space-between", padding: "0 5% 0 5%" }}>
                                            <p id="ChatBotTitle">
                                                {header}
                                            </p>
                                            <span onClick={() => { setOpen(false) }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <MinusIcon color="#000" />
                                            </span>
                                        </div>
                                    </MessageHeader>
                                    <MessageList
                                        loading={loading}
                                    >
                                    </MessageList>
                                    <MessageInput placeholder="Type message here"
                                        onSendMessage={handleSend}
                                        showAttachButton={false} />
                                </MessageContainer>
                            </MainContainer>
                        </MinChatUiProvider>
                    </div>
                </div>
            }
            {(!isOpen || !isMobile) && (<>
                <div
                    className="h-[92%] flex flex-col bg-gray-100 px-4 bg-gradient-to-r from-white to-blue-100"
                >
                    <div
                        className="md:flex justify-between md:h-full"
                    >
                        <div class="flex flex-col justify-between md:w-1/2 p-2 bg-transparent">
                            <div className="">
                                <span className="flex justify-start">
                                    <img
                                        src={Maira}
                                        alt="AI Advisor"
                                        className="w-[64px] h-[64px] mt-6 box-border rounded-full object-cover object-top border-2 border-blue-300"
                                    />
                                    <h1 className="text-6xl font-bold pl-3 pt-6">Maira</h1>
                                </span>
                            </div>
                            <div className="lg:p-10 md: p-5 ">
                                <h1 className="lg:text-4xl text-2xl font-normal text-gray-800 pt-16 brightness-200">
                                    No-code platform for building generative AI-powered smart application
                                </h1>
                                <h2 className="lg:text-lg text-sm text-gray-800 pr-6 pt-4 brightness-200">
                                    Build personalized Generative AI-powered applications that understand your business and answer questions, recommend products, and guide your customers seamlessly.
                                </h2>
                                {isMobile && (
                                    <button
                                        className="text-white mt-8 font-semibold py-2 px-4 rounded-md w-full"
                                        style={{ backgroundColor: "#7aafff" }}
                                        onClick={() => { setOpen(true) }}
                                    >
                                        Click to Try!
                                    </button>
                                )}
                            </div>
                            {!isMobile && (<div />)}{/* Need this empty div to maintain vertical spacing */}
                        </div>
                        {!isMobile && (
                            <div className="flex justify-center xl:pl-9 pt-16 md:w-1/2 p-2 bg-transparent">
                                <div
                                    className="chatbot-container-landing-page shadow-gray-400  shadow-md bg-white rounded-xl xl:w-[50%]"
                                >
                                    <MinChatUiProvider colorSet={{}}>
                                        <MainContainer>
                                            <MessageContainer>
                                                <MessageHeader showBack={false}>
                                                    <div style={{ display: "flex", justifyContent: "space-between", padding: "0 5% 0 5%" }}>
                                                        <p id="ChatBotTitle">{header}</p>
                                                    </div>
                                                </MessageHeader>
                                                <MessageList
                                                    loading={loading}
                                                >
                                                </MessageList>
                                                <MessageInput placeholder="Type message here"
                                                    onSendMessage={handleSend}
                                                    showAttachButton={false} />
                                            </MessageContainer>
                                        </MainContainer>
                                    </MinChatUiProvider>
                                </div>
                            </div>)}
                    </div>

                </div>
                <div class="absolute bottom-0 left-0  w-full bg-gray-800 text-white py-4">
                    <div class="flex flex-col mx-8 md:flex-row justify-between items-center">
                        <div class="text-center md:text-left mb-2 md:mb-0">
                            <p class="text-sm md:text-base">
                                Powered by <span class="font-semibold">Gigalogy Inc.</span>
                            </p>
                            <p class="text-xs text-gray-400">
                                Explore more on our official site
                            </p>
                        </div>

                        <div class="mt-2 md:mt-0">
                            <a href="https://www.gigalogy.com" target="_blank"
                                class="inline-block px-4 py-2 bg-white text-gray-900 text-sm font-medium rounded-md hover:bg-blue-100 transition">
                                Visit Site
                            </a>
                        </div>
                    </div>
                </div>
            </>)}
        </div>
    )
}
export default LandingPage;