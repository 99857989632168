import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './pages/plugin';
import LandingPage from './pages/landing-page';
import {ChatProvider} from "./contexts/ChatProvider";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

ReactDOM.render(
    <React.StrictMode>
      <ChatProvider>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/plugin" element={<App />} />
          </Routes>
        </Router>
      </ChatProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
