import {createContext, useContext, useState, useEffect, useCallback} from "react";
import axios from 'axios';
import {nanoid} from "nanoid";
import {generateUserId, selectAvatarOnMessage} from '../utils/user';
import {  extractAndValidateJson } from '../utils/message';
import { fetchConversations } from '../utils/api'; // Import the modified API functions

const ChatContext = createContext();

const SIZE = 10;
const defaultShojiIcon = "general";
const defaultDoozieIcon = "maira";
const defaultTaigaIcon = "taiga_ai";
export const ChatProvider = ({children}) => {
  const defaultColorSet = {
    // "--message-header-background-color": "#EB144C",
    // ... (add other colors as needed)
  };
  const [clientKey, setClientKey] = useState('');
  const [dataPrefix, setDataPrefix] = useState('');
  const [greeting, setGreeting] = useState('');
  const [header, setHeader] = useState('');

  const [messages, setMessages] = useState([]);

  const [theme, setTheme] = useState('#6ea9d7');
  const [colorSet, setColorSet] = useState(defaultColorSet);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadConversation, setLoadConversation] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [defaultIcon, setDefaultIcon] = useState(defaultDoozieIcon);

  const [userId, setUserId] = useState('');
  const [memberId, setMemberId] = useState('');
  const [sessionId, setSessionId] = useState('');

  const shoji = useCallback(() => {
    return clientKey === "stg-drinkers-client-key" || clientKey === "stg-syurui-client-key" || clientKey === "uEuxYtcROOmfY7WvO3l7JcXmksqyF9p8X6Hen9sNnFM=" || clientKey === "eeF9ylUGxMQ94kMi0qtktRCtHY3adi4klnp921Q4W8g=";
  }, [clientKey]);

  const taiga = useCallback(() => {
    return clientKey === "y6VZ2PKT-zwOc-JLNWsoKEC44zrKiqjhmhV10e3skhg=";
  }, [clientKey]);

  useEffect(() => {
    if (dataPrefix) {
      const storedUserId = localStorage.getItem(dataPrefix + '_userId');
      setUserId(storedUserId || generateUserId());
    }
  }, [dataPrefix]);

  useEffect(() => {
    if (dataPrefix) {
      localStorage.setItem(dataPrefix + '_userId', userId);
    }
  }, [userId, dataPrefix]);

  useEffect(() => {
    if (dataPrefix) {
      const storedSessionId = localStorage.getItem(dataPrefix + '_sessionId');
      if(storedSessionId){
        setSessionId(storedSessionId);
      }
    }
  }, [dataPrefix]);

  useEffect(() => {
    if (dataPrefix) {
      localStorage.setItem(dataPrefix + '_sessionId', sessionId);
    }
  }, [sessionId, dataPrefix]);

  useEffect(() => {
    if (clientKey && shoji()) {
      setDefaultIcon(defaultShojiIcon);
    }else if (clientKey && taiga()){
      setDefaultIcon(defaultTaigaIcon);
    }
  }, [clientKey, shoji, taiga]);


  const sendMessage = (message) => {
    setMessages(prevMessages => prevMessages.concat(message));
  }

  const setCustomTheme = (theme) => {
    setTheme(theme);
  }

  const setCustomColorSet = (colorSet) => {
    setColorSet(colorSet);
  }

  const [showTypingIndicator, setShowTypingIndicator] = useState(false);

  const makeApiCall = async (message) => {
    if(error) {
      setError('');
    }
    const requestData = {
      clientKey,
      message: message,
      user_id: userId,
      member_id: memberId,
      session_id: sessionId,
    };
    try {
      const response = await axios.post(process.env.REACT_APP_MAIRA_AI_ASSISTANT_URL + 'api/ask', requestData);
      const response_session_id = response.data.detail.session_id;
      if(response_session_id) setSessionId(response_session_id);
      const response_msg = response.data.detail.response;
      setStartIndex(prevState => prevState + 1);
      const { parsedJson, nonJsonPart } = extractAndValidateJson(response_msg);

      let currentMessages = [];

      let avatarIcon = defaultIcon;
      if(shoji()){
        avatarIcon = selectAvatarOnMessage(message) || selectAvatarOnMessage(response_msg) || defaultIcon;
      }

      if(nonJsonPart && nonJsonPart !== ""){
        currentMessages.push({
          _id: nanoid(),
          text: nonJsonPart,
          type: 'incoming',
          user: 'remote',
          avatar: avatarIcon,
          createdAt: new Date(),
        });
      }
      if(parsedJson && parsedJson !== null && Object.keys(parsedJson).length === 0 && !currentMessages.length){
        currentMessages.push({
          _id: nanoid(),
          text: "現在、アクセスが集中しています。しばらくしてから再度お試しください。",
          type: 'incoming',
          user: 'remote',
          avatar: avatarIcon,
          createdAt: new Date(),
        });
      }
      else if(parsedJson && parsedJson !== null){
        if(parsedJson.comment){
          currentMessages.push({
            _id: nanoid(),
            text: parsedJson.comment,
            type: 'incoming',
            user: 'remote',
            avatar: avatarIcon,
            createdAt: new Date(),
          });
        }
        if(parsedJson.recommendations){
          currentMessages.push({
            _id: nanoid(),
            text: parsedJson.comment,
            recommendations: parsedJson.recommendations,
            type: 'carousel',
            user: 'remote',
            createdAt: new Date(),
          });
        }
      }

      // if(currentMessages.length === 0){
      //   currentMessages.push({
      //     _id: nanoid(),
      //     text: "I'm sorry, but I couldn't generate any recommendations based on the provided context.",
      //     type: 'incoming',
      //     user: 'remote',
      //     createdAt: new Date(),
      //   });
      // }

      setMessages(prevMessages => prevMessages.concat(currentMessages));
      incomingMessage(currentMessages.length); //Signal to frontend new message is received
    } catch (error) {
      // Handle errors (e.g., update state or log)
      //console.error('API Error:', error);
      setMessages(prevMessages => prevMessages.concat({
        _id: nanoid(),
        text: '現在、アクセスが集中しています。しばらくしてから再度お試しください。',
        type: 'incoming',
        user: 'remote',
        avatar: defaultIcon,
        createdAt: new Date(),
      }));

      incomingMessage(1); //Signal to frontend new message is received
    }
  };

  const fetchConversations = async () => {
    try {
      const requestData = {
        clientKey,
        user_id: userId,
        member_id: memberId,
        start: startIndex,
        size: SIZE
      };
      const response = await axios.post(
          process.env.REACT_APP_MAIRA_AI_ASSISTANT_URL +'api/conversations',
          requestData
      );

      const conversations = response.data;

      if(!conversations.length) {
        if(messages.length === 0 && greeting !== ''){
          sendMessage({
            _id: nanoid(),
            text: greeting,
            avatar: defaultIcon,
            user: "remote",
            type: "incoming",
            createdAt: new Date(),
          });
        }
        return;
      }

      const formattedMessages = conversations.reverse().flatMap(conversation => {
        let currentMessages = [];

        const userMsg = {
          _id: nanoid(),
          text: conversation.request_body.query,
          type: 'outgoing',
          user: 'local',
          createdAt: new Date(conversation.created_at),
        };
        currentMessages.push(userMsg);

        try {
          const response =  conversation.response || conversation.response_msg;
          const { parsedJson, nonJsonPart } = extractAndValidateJson(response);

          let avatarIcon = defaultIcon;
          if(shoji()){
            avatarIcon = selectAvatarOnMessage(conversation.request_body.query) || selectAvatarOnMessage(response) || defaultIcon;
          }

          if(nonJsonPart && nonJsonPart !== ""){
            currentMessages.push({
              _id: nanoid(),
              text: nonJsonPart,
              type: 'incoming',
              user: 'remote',
              avatar: avatarIcon,
              createdAt: new Date(conversation.created_at),
            });
          }

          if(parsedJson && parsedJson !== null && Object.keys(parsedJson).length === 0 && !currentMessages.length){
            currentMessages.push({
              _id: nanoid(),
              text: "現在、アクセスが集中しています。しばらくしてから再度お試しください。",
              type: 'incoming',
              user: 'remote',
              avatar: avatarIcon,
              createdAt: new Date(conversation.created_at),
            });
          }
          else if(parsedJson && parsedJson !== null){
            if(parsedJson.comment){
              currentMessages.push({
                _id: nanoid(),
                text: parsedJson.comment,
                type: 'incoming',
                user: 'remote',
                avatar: avatarIcon,
                createdAt: new Date(conversation.created_at),
              });
            }
            if(parsedJson.recommendations){
              currentMessages.push({
                _id: nanoid(),
                text: parsedJson.comment,
                recommendations: parsedJson.recommendations,
                type: 'carousel',
                user: 'remote',
                avatar: avatarIcon,
                createdAt: new Date(conversation.created_at),
              });
            }
          }
          // if(currentMessages.length === 0){
          //   currentMessages.push({
          //     _id: nanoid(),
          //     text: "I'm sorry, but I couldn't generate any recommendations based on the provided context.",
          //     type: 'incoming',
          //     user: 'remote',
          //     createdAt: new Date(conversation.date),
          //   });
          // }

          return currentMessages;
        } catch (error) {
          currentMessages.push({
            _id: nanoid(),
            text: '現在、アクセスが集中しています。しばらくしてから再度お試しください。',
            type: 'incoming',
            user: 'remote',
            avatar: defaultIcon,
            createdAt: new Date(conversation.created_at),
          });
          return currentMessages;
        }
      });

      setStartIndex(prevState => prevState + SIZE);
      // Update messages state
      setMessages(prevMessages => formattedMessages.concat(prevMessages));
    } catch (error) {
      // Handle errors
      // console.log(error);
      setError(error.response.data.error);
      setLoading(false);
    }
  };
  const hide = () => {
    window.parent.postMessage("hide", "*");
  }
  const show = () => {
    window.parent.postMessage("show", "*");
  }

  const incomingMessage = (count) => {
    window.parent.postMessage({type: "incomingMessage", value: count}, "*");
  }

  return <ChatContext.Provider value={{
    clientKey,
    setClientKey,
    messages,
    sendMessage,
    hide,
    show,
    colorSet,
    setCustomColorSet,
    theme,
    setCustomTheme,
    makeApiCall,
    setShowTypingIndicator,
    showTypingIndicator,
    fetchConversations,
    loading,
    setLoading,
    setLoadConversation,
    loadConversation,
    setGreeting,
    setDataPrefix,
    setHeader,
    header,
    startIndex,
    error,
    setError,
    setUserId,
    setMemberId,
    incomingMessage,
  }}>{children}</ChatContext.Provider>

}

export const useChat = () => {
  const context = useContext(ChatContext);

  if (!context) {
    throw new Error("useChatContext must be within ChatProvider");
  }

  return context;
}